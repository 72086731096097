import { http } from "../../../../common/services/node-service";
import { mapGetters } from 'vuex';

export const getImagesMixin = {
    computed: {
        ...mapGetters({
            getImageSlider: "ecommerce/getImageSlider",
        })
    },
    methods: {
        async afterComplete(upload, type) {
            let formdata = new FormData();
            if (!this.initDropzone) {
                try {
                formdata.append("file", upload);
                formdata.append("type", type);
                formdata.append("name", upload.name);
                await http.post("containers/saveImage", formdata);
                await this.$store.dispatch("ecommerce/getImagenSlider")
                this.containerImg = +1;
                } catch (error) {
                console.log(error);
                }
            }
        },
        async removedFile(file) {
            try {
                await http.delete("containers/deleteImage/" + file.name);
                await this.$store.dispatch("ecommerce/getImagenSlider")
                this.containerImg = +1;
            } catch (error) {
                console.log(error);
            }
        },

        // filterImageVV(){
        //     const images = this.images
        //     this.imagesMain_vidvie = images.data.data.images.filter((image) => {
        //         return image.type === 'bannerMain_vidvie'
        //     })
        //     this.imagesSecondary_vidvie = images.data.data.images.filter((image) => {
        //         return image.type === 'bannerSecondary_vidvie'
        //     })
        // },

        async getImages() {
            try {
                const images = this.getImageSlider
                this.images = images

                this.imagesMain = images.filter((image)=>{
                    return image.type === 'bannerMain'
                })
                this.imagesSecondary = images.filter((image)=>{
                    return image.type === 'bannerSecondary'
                })

                this.imagesMain_vidvie = images.filter((image) => {
                    return image.type === 'bannerMain_vidvie'
                })
                this.imagesSecondary_vidvie = images.filter((image) => {
                    return image.type === 'bannerSecondary_vidvie'
                })
            } catch (error) {
                console.log(error);
            }
        },
    },
};