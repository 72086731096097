<template>
    <layout>
        <b-card no-body v-if="settings.general" :class="'mt-2'">
            <!-- <b-tabs fill card vertical content-class="mt-3" v-model="tabIndex">
                <b-tab :title="$t('ecommerce.general')" href="/general">
                    <general :settings="settings.general" />
                </b-tab>
                <b-tab :title="$t('ecommerce.payment_method')" href="payment-methods">
                    <payment-methods :settings="settings.payment_methods"/>
                </b-tab>
                <b-tab :title="$t('ecommerce.optional_payment')">
                    <buying-options :settings="settings.delivery_options"/>
                </b-tab>
                <b-tab :title="$t('ecommerce.category')" href="categories">
                    <categorias :categories="categories"/>
                </b-tab>
                <b-tab :title="$t('ecommerce.skin')" href="appearance-settings">
                    <appearance-settings/>
                </b-tab>
                <b-tab :title="$t('ecommerce.manage_home')">
                    <manage-home/>
                </b-tab>
            </b-tabs> -->
            <el-tabs :tab-position="tabPosition" v-model="activeTab" class="mt-3" style="height: auto;">
                <el-tab-pane name="tab1" :label="$t('ecommerce.general')" href="/general">
                    <general v-if="activeTab === 'tab1'" :store="store" :settings="settings.general" />
                </el-tab-pane>
                <el-tab-pane name="tab2" :label="$t('ecommerce.payment_method')" href="payment-methods"><payment-methods
                        :settings="settings.payment_methods" v-if="activeTab === 'tab2'" /></el-tab-pane>
                <el-tab-pane name="tab3" :label="$t('ecommerce.optional_payment')"><buying-options
                        :settings="settings.delivery_options" v-if="activeTab === 'tab3'" /></el-tab-pane>
                <el-tab-pane name="tab4" :label="$t('ecommerce.category')" href="categories">
                    <categorias :categories="categories" v-if="activeTab === 'tab4'" />
                </el-tab-pane>
                <el-tab-pane name="tab5" :label="$t('ecommerce.skin')"
                    href="appearance-settings"><appearance-settings v-if="activeTab === 'tab5'" /></el-tab-pane>
                <el-tab-pane name="tab6" :label="$t('ecommerce.manage_home')"><manage-home v-if="activeTab === 'tab6'" /></el-tab-pane>
                <el-tab-pane v-if="generalSetting.general.web_page.country_default.text === 'DO'" name="tab7" :label="$t('ecommerce.manage_ecommerce')">
                    <ManageVidvie v-if="activeTab === 'tab7'" />
                </el-tab-pane>
                <el-tab-pane name="tab8" :label="$t('ecommerce.veltrix_settings')"><veltrix-settings v-if="activeTab === 'tab8'"
                        :settings="settings.veltrix"></veltrix-settings></el-tab-pane>
                <el-tab-pane name="tab9" :label="$t('ecommerce.account_banks')"><bank-account v-if="activeTab === 'tab9'"
                        :settings="settings.general" /></el-tab-pane>
            </el-tabs>
            <template #footer>
                <div class="d-flex justify-content-end">
                    <b-button @click="save" variant="primary">
                        {{ $t('ecommerce.save') }}
                    </b-button>
                </div>
            </template>
        </b-card>
    </layout>
</template>

<script>
import Layout from "@/modules/template/layouts/main";
import { http } from "../../../common/services/node-service";
import General from '../components/settings/General.vue';
import PaymentMethods from '../components/settings/PaymentMethods.vue';
import Categorias from '../components/settings/Categorias.vue';
import BuyingOptions from '../components/settings/BuyingOptions.vue';
import ManageHome from '../components/settings/ManageHome.vue';
import BankAccount from "../components/settings/BankAccount.vue";
import AppearanceSettings from '../components/settings/AppearanceSettings.vue';
import VeltrixSettings from "../components/settings/VeltrixSettings.vue";
import { mapGetters } from 'vuex';
import ManageVidvie from "../components/settings/ManageVidvie.vue";

export default {
    name: "Settings",
    components: {
        Layout,
        General,
        ManageVidvie,
        PaymentMethods,
        Categorias,
        BuyingOptions,
        ManageHome,
        AppearanceSettings,
        VeltrixSettings,
        BankAccount
    },
    data() {
        return {
            tabPosition: 'left',
            activeTab: 'tab1',
            settings: {
                id: 0,
            },
            categories: [],
            tabIndex: 0,
            store: [],
            tabs: [
                'general',
                'payment-methods',
                'buying-options',
                'categories',
                'appearance-settings',
                'manage-home',
                'manage-ecommerce',
            ],
        }
    },
    computed: {
        ...mapGetters({
            generalSetting: "ecommerce/generalSetting",
            permissions: "auth/permissions",
            userData: "auth/user",
            getImageSlider: "ecommerce/getImageSlider",
        })
    },
    watch: {
        generalSetting: {
            deep: true,
            immediate: true,
            handler(val) {
                this.settings = val
                this.settings.id = val._id;
            }
        },
        'getImageSlider'() {
            this.key++
        },
        
        'permissions'() {
            const modules = this.permissions?.modules?.find(data => data.name == 'Ecommerce')
            const setting = modules?.subModules?.find(data => data.name === 'Configuracion')
            // Verifica si se han cambiado los permisos de administrador
            if (!setting.active) {
                // Si ya no es administrador, redirige a otra página (por ejemplo, la página de inicio)
                this.$router.push('/permission');
            }
        },
    },
    methods: {
        async getCategories() {
            try {
                const data = await http.get("categories");
                this.categories = data.data.data;
            } catch (e) {
                console.log(e);
            } finally {
                // $(block).unblock();
            }
        },
        async point_of_sale() {
            try {
                const sale = await http.get('stores')
                this.store = sale.data.data
            } catch (error) {
                console.log(error);
            }
        },
        async save() {
            this.$swal.fire({
                title: 'Confirmacion', text: "Esta seguro de que desea guardar los cambios?",
                icon: "info", showCancelButton: true, confirmButtonText: 'Confirma',
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await http.post("configurations", this.settings);
                        this.$swal.fire({
                            title: 'Guardado con exito', text: "Los cambios se han almacenado",
                            icon: "success",
                        })
                        this.$store.commit("ecommerce/SET_GENERAL_SETTING", this.settings)
                    } catch (error) {
                        if (error.response?.data.message) {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'No se pudo cargar los datos',
                                text: error.response.data.message,
                            })
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'No se pudo cargar los datos',
                                text: error.message,
                            })
                        }
                    }
                }
            })
        },
    },
    async mounted() {
        await this.$store.dispatch("ecommerce/getImagenSlider")
        try {
            await this.getCategories();
        } catch (error) {
            console.error(error);
        }
        this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.params.tab)
    },
    async created() {
        this.point_of_sale()
        this.getPermissions()
    }
}
</script>

<style scoped>
.b-tab {
    margin: 14px
}

.el-tab {
    max-height: auto;
}
</style>
